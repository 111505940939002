/* basic video */
.widget-video
{
  margin-bottom: 20px;
}
.widget-video__player
{
  position: relative;
  background-color: #000;
  background-size: cover;
  background-position: 50% 50%;
  cursor: pointer;
}
.widget-video__player:after
{
  content: '';
  display: block;
  padding-top: 56.25%; /* default 16:9 */
}
.widget-video--aspect_4_3 .widget-video__player:after
{
  padding-top: 75%;
}
.widget-video__player__play
{
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 64px;
  height: 64px;
  margin: -32px 0 0 -32px;
  border-radius: 100%;
  border: 2px solid #fff;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s;
}
.widget-video__player__play > svg
{
  content: '';
  display: block;
  position: absolute;
  width: 26px;
  height: 26px;
  top: 17px;
  left: 24px;
}
.widget-video__player:hover > .widget-video__player__play
{
  background-color: rgba(0, 0, 0, 0.3);
}
.widget-video iframe
{
  position:absolute;
  top: 0;
  left: 0;
  width:100%;
  height:100%;
}
