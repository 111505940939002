.wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  display: flex;
}

.wh-form__fieldgroup {
  align-items: baseline;
}

.wh-form__label, .wh-form__optiondata,
.wh-form__optionlabel,
.wh-form__subfieldlabel {
  display: inline-block;
}

.wh-form__label {
  flex-shrink: 0;
}

.wh-form__fields {
  flex: 1 0 0px;
}

.wh-form__fieldline {
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
}

.wh-form__fieldline:not(.wh-form__fieldline--hidden) {
  display: inline-flex;
}

.wh-form__fieldline--nowrap:not(.wh-form__fieldline--hasinfotext) {
  flex-wrap: nowrap;
}

.wh-form__fieldgroup--horizontal .wh-form__fields {
  display: flex;
  flex-wrap: wrap;
}
.wh-form__fieldgroup--horizontal .wh-form__fieldline {
  flex: none;
  width: auto;
  max-width: 100%;
}

.wh-form__infotextwrapper {
  flex: 0 0 100%;
}

.wh-form__optiondata {
  display: inline-flex;
  width: 100%;
  align-items: baseline;
  flex-grow: 1;
  flex-wrap: wrap;
}
.wh-form__optiondata--vertical {
  width: 0;
}

.wh-form__subfield {
  display: flex;
  flex-grow: 1;
  align-items: baseline;
}

.wh-form__fieldline > input[type=checkbox],
.wh-form__fieldline > input[type=radio] {
  flex-shrink: 0;
}

.wh-form__fieldline > textarea {
  resize: vertical;
}

::-webkit-input-placeholder {
  opacity: 0.7;
  color: inherit;
}

::-moz-placeholder {
  opacity: 0.7;
  color: inherit;
}

:-ms-input-placeholder {
  opacity: 0.7;
  color: inherit;
}

input:-moz-placeholder {
  opacity: 0.7;
  color: inherit;
}

.rpc__busyoverlay {
  display: none;
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
html.dompack--busymodal .rpc__busyoverlay {
  display: block;
}
.rpc__busyoverlay .spinner {
  display: block;
  position: absolute;
  color: #fff;
  font-size: 80px;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  opacity: 0.8;
}

.wh-form__richtext .centercontent {
  padding-right: 0;
  padding-left: 0;
}

.wh-form.wh-styledinput {
  background-color: #ddd;
  padding: 35px;
  margin-bottom: 20px;
  /* */
  /* date/time */
  /* css styled pulldown */
  /* radio / checkbox styling */
}
@media (max-width: 550px) {
  .wh-form.wh-styledinput {
    padding: 20px;
  }
}
@media (max-width: 400px) {
  .wh-form.wh-styledinput {
    padding: 15px;
  }
}
.wh-form.wh-styledinput h3 {
  font-size: 21px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup {
  margin-bottom: 15px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--required > .wh-form__label::after,
.wh-form.wh-styledinput .wh-form__fieldgroup--required .wh-form__label:empty + .wh-form__fields .wh-form__optionlabel::after {
  display: inline-block;
  content: "*";
}
.wh-form.wh-styledinput .wh-form__prefix {
  padding-right: 10px;
  align-self: center;
}
.wh-form.wh-styledinput .wh-form__suffix {
  padding-left: 10px;
  align-self: center;
}
.wh-form.wh-styledinput .wh-form__optionlabel, .wh-form.wh-styledinput .wh-form__subfield > label {
  padding-right: 15px;
}
.wh-form.wh-styledinput .wh-form__label:empty::after {
  display: none;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--richtext > .wh-form__fields {
  max-width: 100%;
}
.wh-form.wh-styledinput .wh-form__fieldgroup {
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  align-items: flex-start;
}
.wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__label {
  padding-bottom: 3px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup .wh-form__subfield {
  flex-wrap: wrap;
}
.wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__label, .wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__fields {
  flex: none;
  max-width: calc(100vw - 40px);
  min-width: 0;
}
.wh-form.wh-styledinput .wh-form__fields {
  width: 100%;
}
.wh-form.wh-styledinput select + .wh-form__subfield > label,
.wh-form.wh-styledinput .wh-form__pulldown-styled + .wh-form__subfield > label {
  padding-left: 10px;
}
.wh-form.wh-styledinput input, .wh-form.wh-styledinput select:not(.wh-rtd__toolbarstyle),
.wh-form.wh-styledinput textarea,
.wh-form.wh-styledinput .wh-form__imgedit,
.wh-form.wh-styledinput .wh-form__dateinputgroup,
.wh-form.wh-styledinput .wh-form__timeinputgroup {
  border: 1px solid #ddd;
  min-height: 44px;
  font-family: inherit;
  font-size: 16px;
  line-height: 20px;
  padding: 11px 15px;
  max-width: 100%;
  color: inherit;
  flex: 1;
  border-radius: 0;
  background-color: #fff;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}
.wh-form.wh-styledinput input:disabled, .wh-form.wh-styledinput input[data-wh-form-disabled], .wh-form.wh-styledinput select:not(.wh-rtd__toolbarstyle):disabled, .wh-form.wh-styledinput select:not(.wh-rtd__toolbarstyle)[data-wh-form-disabled],
.wh-form.wh-styledinput textarea:disabled,
.wh-form.wh-styledinput textarea[data-wh-form-disabled],
.wh-form.wh-styledinput .wh-form__imgedit:disabled,
.wh-form.wh-styledinput .wh-form__imgedit[data-wh-form-disabled],
.wh-form.wh-styledinput .wh-form__dateinputgroup:disabled,
.wh-form.wh-styledinput .wh-form__dateinputgroup[data-wh-form-disabled],
.wh-form.wh-styledinput .wh-form__timeinputgroup:disabled,
.wh-form.wh-styledinput .wh-form__timeinputgroup[data-wh-form-disabled] {
  color: #BFBFBF;
  border-color: #BFBFBF;
  background-color: #F2F2F2;
  box-shadow: none;
}
.wh-form.wh-styledinput input[type=date] {
  max-width: 170px;
}
.wh-form.wh-styledinput input[type=time] {
  max-width: 110px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfield {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  position: relative;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfielddelete {
  background-color: transparent;
  height: 100%;
  align-items: center;
  padding-right: 5px;
  cursor: pointer;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfielddelete::after {
  width: 20px;
  height: 20px;
  color: #ddd;
  font-weight: 900;
  border-radius: 50%;
  background-color: #fff;
  font-size: 20px;
  font-family: "FontAwesome";
  content: "\f057";
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfield--hasfile .wh-form__uploadfielddelete {
  display: flex;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfieldinputholder {
  display: block;
  flex-grow: 100;
  position: relative;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfieldinputholder input {
  width: 100%;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfieldselect {
  margin-left: 15px;
}
.wh-form.wh-styledinput .wh-form--uploading .wh-form__uploadfieldfilename {
  background-image: linear-gradient(135deg, rgba(125, 125, 125, 0.6) 16.67%, transparent 16.67%, transparent 50%, rgba(125, 125, 125, 0.6) 50%, rgba(125, 125, 125, 0.6) 66.67%, transparent 66.67%);
  background-size: 84.85px 84.85px;
  animation: movebackground 2s linear infinite;
}
.wh-form.wh-styledinput .wh-form__imgedit {
  width: 150px;
  height: 200px;
  padding: 0;
  flex: none;
}
.wh-form.wh-styledinput .wh-form__imgedit::after {
  display: flex;
  font-family: "FontAwesome";
  content: "\f382"; /* cloud */
  font-weight: 700;
  font-size: 33px;
  align-items: center;
  justify-content: center;
  background-image: none;
  padding-bottom: 40px;
  color: #4A4A4A;
}
.wh-form.wh-styledinput .wh-form__imgedit .wh-form__imgeditdelete {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 5px;
  right: 10px;
  background-color: transparent;
  cursor: pointer;
}
.wh-form.wh-styledinput .wh-form__imgedit .wh-form__imgeditdelete::before {
  width: 20px;
  height: 20px;
  color: #ddd;
  font-weight: 900;
  border-radius: 50%;
  background-color: #fff;
  font-size: 20px;
  font-family: "FontAwesome";
  content: "\f057";
}
.wh-form.wh-styledinput .wh-form__imgedit .wh-form__imgeditholder::before {
  display: block;
  position: absolute;
  text-align: center;
  font-size: 15px;
  color: #ddd;
  bottom: 0;
  left: 0;
  padding: 20px;
  content: "Drop or upload an image";
}
.wh-form.wh-styledinput .wh-form__imgedit--hasimage::after,
.wh-form.wh-styledinput .wh-form__imgedit--hasimage .wh-form__imgeditholder::before {
  display: none;
}
.wh-form.wh-styledinput .wh-form__imgedit[data-wh-form-disabled]::after {
  opacity: 0.2;
}
.wh-form.wh-styledinput .wh-form__imgedit[data-wh-form-disabled] .wh-form__imgeditholder {
  display: none;
}
.wh-form.wh-styledinput .wh-form__imgeditholder::after {
  z-index: 1;
  content: "\f1ce";
  display: inline-block;
  position: absolute;
  display: none;
  width: 80px;
  top: 50%;
  left: 50%;
  height: 80px;
  font-size: 80px;
  line-height: 80px;
  font-weight: 900;
  font-family: "FontAwesome";
  color: #ddd;
  margin-top: -40px;
  margin-left: -40px;
  opacity: 0.8;
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}
.wh-form.wh-styledinput .wh-form--uploading .wh-form__imgeditholder::after {
  display: inline-block;
}
.wh-form.wh-styledinput .wh-form--uploading .wh-form__imgedit::after,
.wh-form.wh-styledinput .wh-form--uploading .wh-form__imgedit .wh-form__imgeditholder::before {
  display: none;
}
.wh-form.wh-styledinput textarea {
  min-height: 140px;
}
.wh-form.wh-styledinput .wh-rtd__editor {
  width: 100%;
  min-height: 200px;
}
.wh-form.wh-styledinput .wh-rtd__editor .wh-rtd__stylescope {
  padding: 5px 8px;
}
.wh-form.wh-styledinput .wh-rtd__editor .wh-rtd-toolbar select {
  height: 23px;
  border-color: #aaa;
  border-radius: 1px;
  font-size: 15px;
  padding: 0 10px;
  min-height: 22px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--error input, .wh-form.wh-styledinput .wh-form__fieldgroup--error select,
.wh-form.wh-styledinput .wh-form__fieldgroup--error textarea,
.wh-form.wh-styledinput .wh-form__fieldgroup--error .wh-form__imgedit,
.wh-form.wh-styledinput .wh-form__fieldgroup--error .wh-form__dateinputgroup,
.wh-form.wh-styledinput .wh-form__fieldgroup--error .wh-form__timeinputgroup {
  border-color: #D50000;
}
.wh-form.wh-styledinput .wh-form__error {
  display: none;
  flex: none;
  width: 100%; /* force error text below item */
  color: #D50000;
  padding: 8px 0 0;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--error .wh-form__error {
  display: block;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--radiogroup .wh-form__fieldline,
.wh-form.wh-styledinput .wh-form__fieldgroup--checkboxgroup .wh-form__fieldline {
  align-items: baseline;
}
.wh-form.wh-styledinput .wh-form__dateinputgroup, .wh-form.wh-styledinput .wh-form__timeinputgroup {
  flex: none;
  padding: 0;
}
.wh-form.wh-styledinput .wh-form__dateinputgroup input, .wh-form.wh-styledinput .wh-form__timeinputgroup input {
  box-shadow: none;
}
.wh-form.wh-styledinput .wh-form__dateinputgroup__line::after, .wh-form.wh-styledinput .wh-form__timeinputgroup__line::after {
  top: 12px;
}
.wh-form.wh-styledinput .wh-form__pulldown-styled {
  position: relative;
  flex: 1;
}
.wh-form.wh-styledinput .wh-form__pulldown-styled select {
  padding-right: 35px;
  width: 100%;
}
.wh-form.wh-styledinput .wh-form__pulldown-styled select:disabled {
  opacity: 0.4;
}
.wh-form.wh-styledinput .wh-form__pulldown-styled select:disabled + .arrow {
  opacity: 0.4;
}
.wh-form.wh-styledinput .wh-form__pulldown-styled select::-ms-expand {
  display: none; /* hide select arrow in IE */
}
.wh-form.wh-styledinput .wh-form__pulldown-styled .arrow {
  font-size: 22px;
  position: absolute;
  right: 15px;
  top: 50%;
  color: #ddd;
  margin-top: -10px;
  pointer-events: none;
}
.wh-form.wh-styledinput .wh-form__pulldown-styled select[disabled] + .arrow {
  opacity: 0.2;
}
.wh-form.wh-styledinput .wh-form__pulldown-styled:after {
  display: block;
  content: "";
  width: 25px;
  position: absolute;
  z-index: 2; /* On top of select */
  right: 28px;
  top: 3px;
  bottom: 3px;
  pointer-events: none;
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00ffffff", endColorstr="#ffffff",GradientType=1 ); /* IE6-9 */
}
.wh-form.wh-styledinput input[type=radio],
.wh-form.wh-styledinput input[type=checkbox] {
  display: block;
  position: absolute;
  left: -9999px;
  width: 0px;
  height: 0px;
}
.wh-form.wh-styledinput input[type=radio] + label,
.wh-form.wh-styledinput input[type=checkbox] + label {
  vertical-align: top;
  cursor: pointer;
  position: relative;
  top: 3px;
  width: 20px;
  max-width: 20px;
  flex: 0 0 20px;
  height: 20px;
  border: 1px solid #ddd;
  background-color: #fff;
}
.wh-form.wh-styledinput input[type=radio] + label::after,
.wh-form.wh-styledinput input[type=checkbox] + label::after {
  font-family: "FontAwesome";
  content: "";
  font-weight: 700;
  position: absolute;
  top: 0;
  left: 0;
  color: #ddd;
  font-size: 14px;
  display: block;
  padding: 1px 0 0 2px;
  width: 100%;
  height: 100%;
  line-height: 16px;
  transition: all 0.3s;
}
.wh-form.wh-styledinput input[type=radio][disabled] + label,
.wh-form.wh-styledinput input[type=checkbox][disabled] + label {
  border-color: #BFBFBF;
  cursor: default;
}
.wh-form.wh-styledinput input[type=radio][disabled] + label + *,
.wh-form.wh-styledinput input[type=checkbox][disabled] + label + * {
  color: #ddd;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--radiogroup .wh-form__fieldline + .wh-form__fieldline,
.wh-form.wh-styledinput .wh-form__fieldgroup--checkboxgroup .wh-form__fieldline + .wh-form__fieldline {
  margin-top: 10px;
}
.wh-form.wh-styledinput .wh-form__optiondata > * {
  padding-left: 15px;
}
.wh-form.wh-styledinput .wh-form__optiondata--horizontal {
  margin-right: 30px;
  width: 100%;
}
.wh-form.wh-styledinput .wh-form__optiondata--horizontal:last-child {
  margin-right: 0;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--horizontal > .wh-form__fields {
  margin-left: -20px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--horizontal > .wh-form__fields > .wh-form__fieldline {
  padding-left: 20px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--horizontal .wh-form__fieldline--nowrap {
  margin-top: 10px;
}
.wh-form.wh-styledinput input[type=radio] + label {
  border-radius: 50%;
}
.wh-form.wh-styledinput input[type=radio] + label::after {
  border: 3px solid #fff;
  border-radius: 50%;
}
.wh-form.wh-styledinput input[type=radio]:checked + label::after {
  background-color: #ddd;
}
.wh-form.wh-styledinput input[type=radio][disabled] + label::after {
  background-color: #F2F2F2;
  border-color: #F2F2F2;
}
.wh-form.wh-styledinput input[type=radio][disabled]:checked + label::after {
  background-color: #BFBFBF;
}
.wh-form.wh-styledinput input[type=checkbox]:checked + label::after {
  content: "\f00c";
  color: #ddd;
}
.wh-form.wh-styledinput input[type=checkbox][disabled] + label::after {
  content: "";
}
.wh-form.wh-styledinput input[type=checkbox][disabled] + label {
  background-color: #F2F2F2;
}
.wh-form.wh-styledinput input[type=checkbox][disabled]:checked + label::after {
  content: "\f00c";
  color: #BFBFBF;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--error input[type=checkbox]:not(:checked) + label,
.wh-form.wh-styledinput .wh-form__fieldgroup--error input[type=radio]:not(:checked) + label {
  border-color: #D50000;
}
.wh-form.wh-styledinput .wh-form__button {
  cursor: pointer;
  text-transform: uppercase;
  height: 44px;
  outline: none;
  -webkit-appearance: none;
  text-decoration: none;
  white-space: nowrap;
  box-shadow: none;
  overflow: visible; /* IE fix */
  text-align: left;
  font-size: 16px;
  line-height: 20px;
  padding: 11px 15px;
  background-color: #666;
  color: #fff;
  border: 0 none;
  transition: opacity 0.3s;
  position: relative;
  border-radius: 3px;
}
.wh-form.wh-styledinput .wh-form__buttongroup {
  text-align: right;
  padding: 20px 0 0;
}
.wh-form.wh-styledinput .wh-form__buttongroup::after {
  content: "";
  display: block;
  clear: both;
}
.wh-form.wh-styledinput .wh-form__buttongroup .wh-form__button {
  min-width: 200px;
  padding-right: 35px;
}
.wh-form.wh-styledinput .wh-form__buttongroup .wh-form__button::after {
  display: inline-block;
  font-family: "FontAwesome";
  content: "\f054";
  font-weight: 900;
  font-size: 10px;
  line-height: 18px;
  padding-left: 1px;
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -10px;
  width: 20px;
  height: 20px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 50%;
}
.wh-form.wh-styledinput .wh-form__buttongroup .wh-form__button:hover {
  opacity: 0.8;
}
.wh-form.wh-styledinput .wh-form__buttongroup .wh-form__button + .wh-form__button {
  margin-left: 15px;
}
.wh-form.wh-styledinput .wh-form__buttongroup .wh-form__button--previous {
  float: left;
  background-color: #fff;
  border: 1px solid #ddd;
  color: #ddd;
  padding-right: 15px;
  padding-left: 35px;
}
.wh-form.wh-styledinput .wh-form__buttongroup .wh-form__button--previous::after {
  right: auto;
  left: 15px;
  content: "\f053";
}
.wh-form.wh-styledinput .wh-form__buttongroup .wh-form__button--previous:hover {
  background-color: #fff;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.25);
}

@keyframes movebackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 84.85px 84.85px;
  }
}