@charset "UTF-8";
/* Global page, HTML5 setup, style reset */
html, body, p, h1, h2, h3, h4, h5, h6, form {
  padding: 0;
  margin: 0;
}

img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block;
}

audio, canvas, video {
  display: inline-block;
}

datalist, template {
  display: none;
}

*, *:before, *:after {
  -webkit-box-sizing: border-box; /* Android 2.3 still requires this */
  -moz-box-sizing: border-box; /* For people still running firefox 24ESR */
  box-sizing: border-box;
}

/* Defaults */
html {
  background-color: #ddd;
  color: #666;
}

body {
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  overflow: hidden;
  overflow-y: auto;
  -webkit-font-smoothing: antialiased;
}

.centercontainer {
  max-width: 100%;
  min-height: 100vh;
  width: 1280px;
  padding: 0;
  margin: 0 auto;
  display: flex;
}

#mobileheader {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 41px;
  background-color: #070707;
  border-bottom: 2px solid #ccc;
  z-index: 1000;
}
#mobileheader > .logo {
  position: absolute;
  display: block;
  left: 20px;
  top: 4px;
  height: 32px;
  width: 23px;
}
#mobileheader > .logo > img {
  height: 100%;
  width: auto;
  vertical-align: bottom;
}
#mobileheader .togglemobile {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
}
#mobileheader .togglemobile > span {
  display: inline-block;
  width: 20px;
  height: 0px;
  border-bottom: 2px solid #fff;
  position: absolute;
  transition: transform 0.2s;
  top: 9px;
  left: 0;
}
#mobileheader .togglemobile > span:before, #mobileheader .togglemobile > span:after {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  left: 0;
  width: 20px;
  border-top: 2px solid #fff;
}
#mobileheader .togglemobile > span:after {
  top: -7px;
}
html.mobilemenu #mobileheader .togglemobile > span {
  transform: rotate(45deg);
}
html.mobilemenu #mobileheader .togglemobile > span:before {
  transform: rotate(90deg) translate(-6px, 0);
}
html.mobilemenu #mobileheader .togglemobile > span:after {
  transform: rotate(90deg) translate(6px, 0);
}

#mobilemenu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 0;
  background-color: #070707;
  color: #fff;
  transition: width 200ms;
  display: none;
  z-index: 500;
  overflow: hidden;
  max-width: 100%;
}
#mobilemenu > .menucontainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 320px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 60px 20px;
  background-color: inherit;
}
#mobilemenu > .menucontainer .logo {
  width: 100%;
  height: auto;
}
#mobilemenu > .menucontainer a {
  color: inherit;
}
#mobilemenu > .menucontainer #mainnav ul {
  list-style-type: none;
  margin: 0;
  padding: 20px 0;
}
#mobilemenu > .menucontainer #mainnav ul a {
  text-decoration: none;
  font-size: 16px;
  line-height: 20px;
  height: 40px;
  padding: 10px 0;
  font-weight: 600;
  white-space: nowrap;
  max-width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
#mobilemenu > .menucontainer #mainnav ul > li {
  display: block;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  position: relative;
  color: #ccc;
}
#mobilemenu > .menucontainer #mainnav ul > li + li {
  margin-top: -1px;
}
#mobilemenu > .menucontainer #mainnav ul > li.active, #mobilemenu > .menucontainer #mainnav ul > li:hover {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  z-index: 1;
  color: #fff;
}
#mobilemenu > .menucontainer .contact {
  padding-top: 10px;
  font-weight: bold;
  font-size: 14px;
}
#mobilemenu > .menucontainer .contact a {
  text-decoration: none;
}
#mobilemenu > .menucontainer .contact a:hover {
  text-decoration: underline;
}
#mobilemenu > .menucontainer .phone > .fa, #mobilemenu > .menucontainer .email > .fa {
  padding-right: 7px;
}
@media (max-width: 640px) {
  #mobilemenu {
    display: block;
  }
  html.mobilemenu #mobilemenu {
    width: 320px;
    overflow: visible;
  }
  html.mobilemenu #mobilemenu:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(100, 100, 100, 0.7);
  }
}

@media (max-width: 640px) {
  html.mobilemenu body {
    overflow: hidden;
  }
}
aside {
  width: 50%;
  flex: 1;
  background-color: #070707;
  padding: 35px 0;
  color: #fff;
}
aside .logo {
  display: block;
  margin: 0 35px;
  position: relative;
  z-index: 10; /* on top of image */
}
aside .logo > img {
  width: 100%;
  height: auto;
}
aside .image {
  position: relative;
  margin-top: 35px;
}
aside .image > img {
  vertical-align: bottom;
  width: 100%;
  height: auto;
}
aside .image--desktop {
  display: none;
}
aside .image--mobile {
  display: block;
}
@media (min-width: 640px) {
  aside .image--desktop {
    display: block;
  }
  aside .image--mobile {
    display: none;
  }
}
html.homepage aside .image {
  margin-top: 0;
}
html.homepage aside .image:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  height: 20px;
  background: -moz-linear-gradient(top, rgb(7, 7, 7) 0%, rgba(7, 7, 7, 0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgb(7, 7, 7) 0%, rgba(7, 7, 7, 0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgb(7, 7, 7) 0%, rgba(7, 7, 7, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#070707", endColorstr="#00070707",GradientType=0 ); /* IE6-9 */
}
html.homepage aside .image:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 25px;
  background: -moz-linear-gradient(top, rgba(7, 7, 7, 0) 0%, rgb(7, 7, 7) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(7, 7, 7, 0) 0%, rgb(7, 7, 7) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(7, 7, 7, 0) 0%, rgb(7, 7, 7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00070707", endColorstr="#070707",GradientType=0 ); /* IE6-9 */
}
aside .slogan {
  padding: 32px 0 0;
  text-align: center;
}
aside .slogan > img {
  width: 227px;
  height: auto;
}
@media (max-width: 750px) {
  aside .slogan > img {
    width: 30vw;
  }
}
@media (max-width: 640px) {
  aside .slogan > img {
    width: 227px;
  }
}
@media (max-width: 400px) {
  aside .slogan > img {
    width: 56.75vw;
  }
}

.fa-twitter::before {
  content: "𝕏" !important;
  font-size: 100%;
  font-weight: 400;
  font-family: "Arial Black", Arial;
}

main {
  background-color: #fff;
  width: 50%;
  flex: 1;
  padding: 35px 0 150px;
  position: relative;
}
main #mainnav ul {
  list-style-type: none;
  margin: 0 0 0 -10px;
  padding: 0 35px 15px;
}
main #mainnav li {
  display: inline-block;
  margin-bottom: 10px;
  margin-left: 10px;
}
main #mainnav a {
  display: inline-block;
  text-decoration: none;
  border: 1px solid #666;
  border-radius: 3px;
  color: #eee;
  background-color: #666;
  font-size: 15px;
  font-weight: 600;
  height: 26px;
  line-height: 23px;
  padding: 0 15px;
  transition: all 0.2s;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
main #mainnav li.active > a, main #mainnav a:hover {
  background-color: #fff;
  color: #070707;
}
main article {
  padding: 0 35px;
}
main article:after {
  content: "";
  clear: both;
  display: block;
}
main footer {
  position: absolute;
  bottom: 0;
  left: 35px;
  right: 35px;
  border-top: 1px solid #ddd;
  padding: 15px 0;
}
main footer a {
  color: inherit;
}
main footer #sharepage {
  position: absolute;
  top: -40px;
  right: 0;
  white-space: nowrap;
  vertical-align: top;
}
main footer #sharepage > span {
  display: inline-block;
  position: relative;
  top: -2px;
}
main footer #sharepage ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: inline-block;
}
main footer #sharepage ul > li {
  padding: 3px 5px;
  font-size: 20px;
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}
main footer > .address, main footer > .contact {
  white-space: nowrap;
  display: inline-block;
  vertical-align: top;
}
main footer > .contact {
  position: absolute;
  right: 0;
  top: 15px;
  width: auto;
}
main footer .phone > .fa, main footer .email > .fa {
  padding-right: 7px;
}

/* contentlist */
.contentlist {
  list-style-type: none;
  margin: 30px 0 15px;
  padding: 0;
}
.contentlist a {
  color: inherit;
  text-decoration: none;
  background-color: #fff;
  transition: background-color 0.2s;
  display: block;
  padding: 10px 35px;
}
.contentlist a:hover {
  background-color: #f9f9f9;
}
.contentlist__item__title {
  font-size: 18px;
  display: block;
}
.contentlist__item__description {
  display: block;
}

@media (max-width: 1000px) {
  aside {
    padding: 30px 0;
  }
  aside .logo {
    margin: 0 30px;
  }
  aside .image {
    margin-top: 30px;
  }
  html.homepage aside .image {
    margin-top: 0;
  }
  aside .slogan {
    padding-top: 30px;
  }
  main {
    padding-top: 30px;
  }
  main #mainnav ul {
    padding: 0 30px 30px;
  }
  main article {
    padding: 0 30px;
  }
  main footer {
    left: 30px;
    right: 30px;
  }
  .contentlist a {
    padding: 10px 30px;
  }
}
@media (max-width: 900px) {
  main {
    padding-bottom: 200px;
  }
  main footer > .contact {
    display: block;
    position: relative;
    right: auto;
    top: auto;
  }
}
@media (max-width: 800px) {
  aside {
    padding: 25px 0;
  }
  aside .logo {
    margin: 0 25px;
  }
  aside .image {
    margin-top: 25px;
  }
  html.homepage aside .image {
    margin-top: 0;
  }
  aside .slogan {
    padding-top: 25px;
  }
  main {
    padding-top: 25px;
  }
  main #mainnav ul {
    padding: 0 25px 25px;
  }
  main article {
    padding: 0 25px;
  }
  main footer {
    left: 25px;
    right: 25px;
  }
  .contentlist a {
    padding: 10px 25px;
  }
}
@media (max-width: 640px) {
  html {
    background-color: #070707;
  }
  .centercontainer {
    display: block;
  }
  body {
    padding-top: 40px;
  }
  #mobileheader {
    display: block;
  }
  aside {
    width: 100%;
    padding-bottom: 0;
    padding-top: 0;
  }
  aside .logo {
    display: none;
  }
  aside .slogan {
    display: none;
  }
  aside .image {
    margin-top: 0;
  }
  html.homepage aside {
    padding-bottom: 15px;
    padding-top: 20px;
  }
  html.homepage aside .logo, html.homepage aside .slogan {
    display: block;
  }
  html.homepage aside .image {
    margin-top: -9vw;
  }
  main {
    width: 100%;
    padding-bottom: 150px;
  }
  main #mainnav {
    display: none;
  }
  main footer > .contact {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 15px;
  }
}
@media (max-width: 550px) {
  html.homepage aside {
    padding: 20px 0;
  }
  html.homepage aside .logo {
    margin: 0 20px;
  }
  html.homepage aside .slogan {
    padding-top: 20px;
  }
  main {
    padding-top: 20px;
  }
  main article {
    padding: 0 20px;
  }
  main footer {
    left: 20px;
    right: 20px;
  }
  .contentlist a {
    padding: 10px 20px;
  }
}
@media (max-width: 450px) {
  main {
    padding-bottom: 200px;
  }
  main footer > .contact {
    display: block;
    position: relative;
    right: auto;
    top: auto;
  }
}
@media (max-width: 400px) {
  #mobileheader > .logo {
    left: 15px;
  }
  #mobileheader .togglemobile {
    right: 15px;
  }
  #mobilemenu > .menucontainer {
    padding-left: 15px;
    padding-right: 15px;
  }
  html.homepage aside {
    padding: 15px 0;
  }
  html.homepage aside .logo {
    margin: 0 15px;
  }
  html.homepage aside .slogan {
    padding-top: 15px;
  }
  main {
    padding-top: 15px;
  }
  main article {
    padding: 0 15px;
  }
  main footer {
    left: 15px;
    right: 15px;
  }
  .contentlist a {
    padding: 10px 15px;
  }
}